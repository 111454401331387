<template>
  <div class="help-center">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>

    <section class="section-space-b ">
      <div class="container">
        <SectionHeading classname="text-center" text="Frequently Asked Questions"
                        content="If you could not find an answer to your questions, please contact our customer support"
                        isMargin="mb-3 mt-5"></SectionHeading>
        <!-- section heading -->
        <div class="list-box ">
          <div class="list-item" v-for="(item, index) in dataList" :key="index">
            <div class="label">{{ item.title }}</div>
            <div class="value mt-2" v-html="item.content"></div>
          </div>

        </div>
      </div><!-- .container -->
    </section><!-- end blog-section -->


<!--    <section class="section-space-b">-->
<!--      <div class="container">-->
<!--        &lt;!&ndash; section heading &ndash;&gt;-->
<!--        <SectionHeading classname="text-center" text="Get In Touch with Us" content="" isMargin="mb-3"></SectionHeading>-->
<!--        <div class="row">-->
<!--          <ContactSection></ContactSection>-->
<!--          &lt;!&ndash;          <div class="col-6">&ndash;&gt;-->
<!--          &lt;!&ndash;            <SectionHeading classname="text-center" text="Get In Touch with Us" content="" isMargin="mb-3"></SectionHeading>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="col-6"></div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>&lt;!&ndash; .container &ndash;&gt;-->
<!--    </section>-->


    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div>
</template>

<script>
import ContactSection from "@/components/n1/ContactSection";
import HeaderMain from "@/components/n1/HeaderMain"
import Footer from "@/pages/n1/Footer"

export default {
  name: "HelpCenter",
  components: {
    ContactSection,
    HeaderMain,
    Footer
  },
  data() {
    return {
      login: false,
      dataList: [{
        title: "1. What is Cloud Mining?",
        content: "Cloud mining is a method of cryptocurrency mining where individuals lease computing power from remote data centers, eliminating the need to purchase and maintain hardware. Miners rent hashing power from third-party providers to participate in mining, and rewards are proportionate to the rented computing capacity."
      }, {
        title: "2. How do i get started with GDMining?",
        content: "Register for a free membership account using your email address, then top up your account, choose mining contracts you want to invest in, and start earning daily returns!"
      }, {
        title: "3. What are GDMining Advantages?",
        content: "Join GDMining, where you don't need hardware/software/technical expertise, nor do you need to research cloud mining trends or market fluctuations. Our experienced investment team and IT experts will provide you with the best market-leading technology. Simply select the Cloud Mining contracts you need to invest in. Even if you're a beginner, you can get started quickly and benefit from it.\n"
      }, {
        title: "4. Can I buy more than one Cloud Mining contract?",
        content: "Yes, you can buy multiple contracts, even contracts of different terms."
      }, {
        title: "5. When are rewards paid out?",
        content: "The rewards are paid out daily and withdrawable anytime."
      }, {
        title: "6. Do I need to pay electricity and maintenance costs?",
        content: "No, no extra fees. The electricity and maintenance costs are included in the contract cost."
      }, {
        title: "7. Is there a minimum or maximum purchase amount?",
        content: "Minimum purchase amounts differs for every contract, you may find it on our pricing page."
      }, {
        title: "8. Is there any minimum withdrawal amount?",
        content: "Our minimum withdrawal is $200."
      }, {
        title: "9. What payment methods do you support?",
        content: "You can pay for mining contracts using cryptocurrencies such as BTC,ETH,LTC,USDT."
      }, {
        title: "10. What is the affiliates program?",
        content: "Every GDMining customer has a unique referral link, which can be shared with anyone. Copy your referral link and share it to get bonuses. You can start earning money even if you do not invest. As a referrer, you are eligible to receive a 3% bonus based on their initial investment amount. For example, if someone signs up using your referral code and deposits $1000, you'll receive $30 for free."
      }],
    }
  }
}
</script>

<style scoped lang="scss">
.bg-cus {
  background-color: #ffe5d0;
}

.list-box {
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  .list-item {
    padding: 1rem 1.8125rem;

    .label {

      font-size: 1rem;
      //font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      line-height: 1.5rem;
    }

    .value {
      font-size: 0.875rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 1.375rem;
    }
  }
}
</style>
